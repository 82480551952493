import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './app.css';
import {AccordionBlock} from './components/AccordionBlock';
import {BreadCrumbs} from './components/BreadCrumbs';
import {ContactUs} from './components/ContactUs';
import {CustomSlider} from './components/CustomSlider';
import {DealerOrderForm} from './components/Dealers/DealerOrderForm';
import {LeftNav} from './components/Dealers/LeftNav';
import {SelectPrice} from './components/Dealers/SelectPrice';
import {ExportPage} from './components/ExportPage';
import {FindAStore} from './components/FindAStore';
import {FindAStoreContentBlock} from './components/FindAStoreContentBlock';
import {FitMy4x4} from './components/FitMy4x4';
import {FitMy4x4Filter} from './components/FitMy4x4Filter';
import {ItemSlider} from './components/ItemSlider';
import {NavBar} from './components/NavBar';
import {NonDealerPriceDialog} from './components/NonDealerPriceDialog';
import {OnlineQuote} from './components/OnlineQuote';
import {OrderItems} from './components/OrderItems';
import {OrderTable} from './components/OrderItemSearch';
import {ResourcesTable} from './components/ResourcesTable';
import {Vehicles} from './components/Vehicles';

const sliders = document.querySelectorAll('[data-react="slider"]');
Array.from(sliders).forEach(slider => {
  if (slider instanceof HTMLElement) {
    const root = ReactDOM.createRoot(slider!);
    //@ts-ignore
    const reactProps = JSON.parse(slider.dataset.props);

    root.render(<CustomSlider slides={reactProps.slides} centerMode={!!reactProps.centerMode || false}/>);
  }
});

const itemSliders = document.querySelectorAll('[data-react="itemSlider"]');
Array.from(itemSliders).forEach(slider => {
  if (slider instanceof HTMLElement) {
    const root = ReactDOM.createRoot(slider!);
    //@ts-ignore
    const reactProps = JSON.parse(slider.dataset.props);

    root.render(<ItemSlider slides={reactProps.slides}/>);
  }
});

const findMy4x4 = document.querySelectorAll('[data-react="fitMy4x4"]');
Array.from(findMy4x4).forEach(item => {
  if (item instanceof HTMLElement) {
    const root = ReactDOM.createRoot(item!);
    root.render(<FitMy4x4/>);
  }
});

const selectPrice = document.querySelectorAll('[data-react="selectPrice"]');
Array.from(selectPrice).forEach(item => {
  if (item instanceof HTMLElement) {
    const root = ReactDOM.createRoot(item!);
    root.render(<SelectPrice/>);
  }
});

const findMy4x4Filter = document.querySelectorAll('[data-react="fitMy4x4Filter"]');
Array.from(findMy4x4Filter).forEach(item => {
  if (item instanceof HTMLElement) {
    const root = ReactDOM.createRoot(item!);
    const reactProps = item.dataset.props ? JSON.parse(item.dataset.props) : null;
    root.render(<FitMy4x4Filter href={reactProps?.href}/>);
  }
});

const nonDealerPlaceDialogs = document.querySelectorAll('[data-react="nonDealerPriceDialog"]');
Array.from(nonDealerPlaceDialogs).forEach(item => {
  if (item instanceof HTMLElement) {
    const root = ReactDOM.createRoot(item!);
    root.render(<NonDealerPriceDialog/>);
  }
});

const accordionBlocks = document.querySelectorAll('[data-react="accordionBlock"]');
Array.from(accordionBlocks).forEach(accordionBlock => {
  if (accordionBlock instanceof HTMLElement) {
    const root = ReactDOM.createRoot(accordionBlock!);

    const reactProps = JSON.parse(accordionBlock.dataset.props || '{}');
    root.render(<AccordionBlock rows={reactProps.rows} containsImage={reactProps.containsImage} darkTheme={reactProps.darkTheme}/>);
  }
});

const exportPages = document.querySelectorAll('[data-react="exportPage"]');

Array.from(exportPages).forEach(exportPage => {
  if (exportPage instanceof HTMLElement) {
    const root = ReactDOM.createRoot(exportPage!);
    const reactProps = JSON.parse(exportPage.dataset.props);
    root.render(<ExportPage creditorCodes={reactProps.creditorCodes}/>);
  }
});


const navBar = document.querySelectorAll('[data-react="navBar"]');
Array.from(navBar).forEach(nav => {
  if (nav instanceof HTMLElement) {
    const root = ReactDOM.createRoot(nav!);
    // @ts-ignore
    const reactProps = JSON.parse(nav.dataset.props);
    root.render(<NavBar whiteNav={reactProps.whiteNav} links={reactProps.links} footerLinks={reactProps.footerLinks}
                        productsInQuote={Boolean(reactProps.productsInQuote)}/>);
  }
});

const findAStorePage = document.querySelectorAll('[data-react="findAStore"]');
Array.from(findAStorePage).forEach(findAStore => {
  if (findAStore instanceof HTMLElement) {
    const root = ReactDOM.createRoot(findAStore!);
    // @ts-ignore
    const reactProps = JSON.parse(findAStore.dataset.props);
    root.render(<FindAStore {...reactProps}/>);
  }
});

const vehiclesSections = document.querySelectorAll('[data-react="vehicles"]');
Array.from(vehiclesSections).forEach(vehicles => {
  if (vehicles instanceof HTMLElement) {
    const root = ReactDOM.createRoot(vehicles!);
    // @ts-ignore
    const reactProps = JSON.parse(vehicles.dataset.props);
    root.render(<Vehicles {...reactProps}/>);
  }
});

const breadCrumbs = document.querySelectorAll('[data-react="breadCrumbs"]');
Array.from(breadCrumbs).forEach(breadCrumbComponent => {
  if (breadCrumbComponent instanceof HTMLElement) {
    const root = ReactDOM.createRoot(breadCrumbComponent!);
    // @ts-ignore
    const dataset = breadCrumbComponent.dataset.props;
    const reactProps = dataset ? JSON.parse(dataset) : undefined;
    root.render(<BreadCrumbs ignore={reactProps?.ignore} endText={reactProps?.endText}/>);
  }
});

const findAStoreContentBlock = document.querySelectorAll('[data-react="findAStoreContentBlock"]');
Array.from(findAStoreContentBlock).forEach(findAStore => {
  if (findAStore instanceof HTMLElement) {
    const root = ReactDOM.createRoot(findAStore!);
    // @ts-ignore
    const reactProps = JSON.parse(findAStore.dataset.props);
    root.render(<FindAStoreContentBlock darkTheme={reactProps.darkTheme} href={reactProps.link}/>);
  }
});

const onlineQuotes = document.querySelectorAll('[data-react="onlineQuote"]');
Array.from(onlineQuotes).forEach(onlineQuote => {
  if (onlineQuote instanceof HTMLElement) {
    const root = ReactDOM.createRoot(onlineQuote!);
    // @ts-ignore
    const reactProps = JSON.parse(onlineQuote.dataset.props);
    root.render(<OnlineQuote {...reactProps}/>);
  }
});

const contactUs = document.querySelectorAll('[data-react="contactUs"]');
Array.from(contactUs).forEach(contactUs => {
  if (contactUs instanceof HTMLElement) {
    const root = ReactDOM.createRoot(contactUs!);
    root.render(<ContactUs/>);
  }
});

const resourcesTable = document.querySelectorAll('[data-react="resourcesTable"]');
Array.from(resourcesTable).forEach(resourcesTableItem => {
  if (resourcesTableItem instanceof HTMLElement) {
    const root = ReactDOM.createRoot(resourcesTableItem!);

    // @ts-ignore
    root.render(<ResourcesTable/>);
  }
});

const orderItemsTable = document.querySelectorAll('[data-react="orderItemsTable"]');
Array.from(orderItemsTable).forEach(orderItemsTableItem => {
  if (orderItemsTableItem instanceof HTMLElement) {
    const root = ReactDOM.createRoot(orderItemsTableItem!);

    // @ts-ignore
    root.render(<OrderTable/>);
  }
});


const orderItems = document.querySelectorAll('[data-react="orderItems"]');
Array.from(orderItems).forEach(contactUs => {
  if (contactUs instanceof HTMLElement) {
    const root = ReactDOM.createRoot(contactUs!);

    //trigger event
    root.render(<OrderItems/>);
  }
});

const dealerOrderForm = document.querySelector('[data-react="dealerOrderForm"]');
if (dealerOrderForm) {
  const root = ReactDOM.createRoot(dealerOrderForm!);
  root.render(<DealerOrderForm/>);
}


//Dealer blocks
const leftNavs = document.querySelectorAll('[data-react="leftNav"]');
Array.from(leftNavs).forEach(leftNav => {
  if (leftNav instanceof HTMLElement) {
    const root = ReactDOM.createRoot(leftNav!);
    // @ts-ignore
    const reactProps = JSON.parse(leftNav.dataset.props);
    root.render(<LeftNav logoutUrl={reactProps.logoutUrl}/>);
  }
});

const userFormFields = document.querySelector('.userform')?.querySelectorAll('input');
Array.from(userFormFields || []).forEach(formField => {
  if (formField.required) {
    if (formField.type === 'checkbox') {
      let checkboxLabel = document.querySelector(`label[for="${formField.id}"]`);
      if (checkboxLabel) {
        checkboxLabel.textContent = checkboxLabel?.textContent + '*';
      }
    } else {
      formField.placeholder = formField.placeholder + '*';
    }
  }
});


const grids = document.querySelectorAll('.masonry-grid');
// @ts-ignore
Array.from(grids).forEach(grid => {
  let msnry = new Masonry(grid, {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    horizontalOrder: true,
  });

// @ts-ignore
  imagesLoaded(grid).on('progress', function () {
    // layout Masonry after each image loads
    // @ts-ignore
    msnry?.layout();
  });
});



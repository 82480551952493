'use strict';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import * as Accordion from '@radix-ui/react-accordion';
import cls from 'classnames';
import React from 'react';

interface AccordionLine {
  title: string;
  content: string;
}

export const AccordionBlock = ({rows, containsImage, darkTheme}: { rows: AccordionLine[], containsImage: boolean, darkTheme: boolean }) => (
  <div className={cls('flex flex-col', containsImage ? 'xl:w-155 w-full' : 'w-full')}>
    <Accordion.Root
      className={cls('flex flex-col w-full', containsImage ? 'lg:w-155 xl:mx-0 mx-auto' : 'lg:w-3/5')}
      type="single"
      collapsible
    >
      {
        rows.map((question, index) => (
            <Accordion.Item value={'item-' + index} key={index}
                            className={cls('duration-300 data-[state=open]:shadow-home data-[state=closed]:border-b data-[state=open]:rounded-box', darkTheme ? 'border-gray-600' : 'border-black/10')}>
              <div className="flex space-x-6">
                <div className="flex flex-col w-full ml-9">
                  <Accordion.Header
                    className={cls('text-xl uppercase font-bold py-8 flex justify-between items-center', darkTheme ? 'text-white' : 'text-black')}>
                    {question.title}
                    <Accordion.Trigger className={cls('focus-within:border-0 focus:border-0 AccordionTrigger', darkTheme && 'darkTheme')}>
                      <ChevronDownIcon className={cls('h-6 w-6 hover:text-green AccordionChevron', darkTheme ? 'text-white' : 'text-black')}/>
                    </Accordion.Trigger>
                  </Accordion.Header>

                  <Accordion.Content className="data-[state=open]:animate-hideContent data-[state=closed]:animate-showContent overflow-hidden">
                    <div className="prose lg:prose-base prose-sm 2xl:pr-40 sm:pr-28 pr-9 pb-9">
                      <div className={cls(darkTheme ? 'text-white' : 'text-black')} dangerouslySetInnerHTML={{__html: question.content}}/>
                    </div>
                  </Accordion.Content>
                </div>
              </div>
            </Accordion.Item>
          ),
        )
      }
    </Accordion.Root>
  </div>
);



'use strict';
import {CogIcon} from '@heroicons/react/20/solid';
import cls from 'classnames';
import React, {useState} from 'react';
import {Dialog, DialogContent} from './Dialog';
import {SelectField} from './FormFields/SelectField';
import {PrimaryButton} from './PrimaryButton';

export function ExportPage({creditorCodes}: {creditorCodes: { value: string | number, label: string }[]}) {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState<string | null>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);

  const exportButtonPressed = (e: any) => {
    e.preventDefault()

    if (option) {
      setLoading(true);
      fetch('/dealers/api/downloadProductSpreadsheet?CreditorCode=' + option)
        .then(() => {
          setOpenSuccessModal(true);
        })
        .catch(error => {
          console.error('Error:', error);
          setLoading(false);
        }).finally(() => {
          setLoading(false);
        }
      );
    }
  };

  return (
    <form className="flex flex-col gap-y-10 my-4">
      <SelectField invertColor={true} title="Creditor Codes" value={option} onChange={(value) => {
        setOption(value.target.value);
      }} options={creditorCodes} required={true}/>
      <PrimaryButton onClick={exportButtonPressed} className={cls('flex justify-center gap-4 flex-grow-0', loading && 'opacity-50 pointer-events-none hover:opacity-50')}>
        {loading && <CogIcon className="h-6 w-6 animate-spin"/>}
        <span>Export Products</span>
      </PrimaryButton>
      <Dialog open={openSuccessModal}>
        <DialogContent>
          <div className="flex flex-col gap-6">
            <h2 className="text-3xl font-normal">Dealer Export emailed</h2>
            <p>Your export will be completed shortly, keep an eye on your emails in the meantime</p>
            <div className="flex gap-4 justify-end">
              <button onClick={() => setOpenSuccessModal(false)} className="bg-green py-2 px-4 rounded-lg">Ok</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openErrorModal}>
        <DialogContent>
          <div className="flex flex-col gap-6">
            <h2 className="text-3xl font-normal">Dealer Export Failed</h2>
            <p>Export file failed to generate. Please try submit again. If the problem persists, please contact your Administrator</p>
            <div className="flex gap-4 justify-end">
              <button onClick={() => setOpenErrorModal(false)} className="bg-green py-2 px-4 rounded-lg">Ok</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </form>
  );
}

import {mdiClose} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import React from 'react';

interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  title: string;
  onChange: any;
  clear?: any;
  value: any;
  options: { value: any, label: string }[];
  invertColor?: boolean;
}

export const SelectField = ({
  title,
  onChange,
  clear,
  value,
  options,
  invertColor = false,
  ...props
}: SelectFieldProps) =>
  (
    <div
      className={'w-full border-b border-gray-600 z-10 relative flex ' + (invertColor ? ' hover:border-black' : 'hover:border-white')}>
      <select {...props}
              className={cls('w-full outline-0 border-0 py-4', invertColor ? 'bg-white ' + (value ? 'text-black' : 'hover:text-black text-gray-500') : 'bg-black ' + (value ? 'text-white' : 'hover:text-white text-white'))}
              placeholder={title}
              onChange={onChange}
              value={value}
      >
        <option className="hidden" value="" hidden>{title}</option>
        {options.map(option => <option key={option.value} value={option.value}
                                       className={'text-black'}>{option.label}</option>)}
      </select>
      {value && clear ?
        <button onClick={clear}
                type="button"
                className={cls('top-5 right-4', invertColor ? 'text-black' : 'text-gray-500')}><Icon
          path={mdiClose}
          className={cls('h-4', invertColor ? 'text-black' : 'text-white')}/>
        </button> : null}
    </div>
  );
